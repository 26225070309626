
import * as React from "react";

import bannerImage from "../../assets/images/aboutUs/aboutUsBanner.jpg";

import {Banner,} from "../../reusable/organisms";

import { DefaultLayout } from "../../reusable/Templates";
import { Report } from "../../reusable/home-components";

const Reports = () => {
  return (
    <DefaultLayout>
      <Banner
        image={bannerImage}
        subtitle="Reports page"
        title="Reports"
      />
      <Report />
    
    </DefaultLayout>
  );
};

export default Reports;
